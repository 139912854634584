import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ChatterComponent} from './chatter/chatter.component';

@NgModule({
  declarations: [
    AppComponent,
    ChatterComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [
    AppComponent,
    ChatterComponent
  ]
})
export class AppModule {
}
