import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'soCapX';
  toggleUserCategory: false;
  toggleOrgLinkages: false;
  toggleUserDetails: false;
  toggleMessages: false;


  changeAvatar(imgs) {
    var expandImg = document.getElementById('expandedImg');
    console.log(expandImg);
    expandImg = imgs.src;
    expandImg.parentElement.style.display = 'block';
  }
}
